// src/hooks/useFetchVehicles.js
import { useState, useEffect } from 'react';
import axios from 'axios';

/**
 * Custom hook to fetch vehicles data from the API with pagination.
 * Manages vehicles data, loading state, error state, and pagination.
 * 
 * @param {number} page - The current page number.
 * @param {number} limit - The number of vehicles to fetch per page.
 * @param {Object} filters - The filter parameters.
 * @returns {Object} An object containing vehicles data, loading state, error state, and pagination controls.
 */

const useFetchVehicles = (page = 1, limit = 500, filters = {}) => { // Accept filters
  const [vehicles, setVehicles] = useState([]); // State to hold fetched vehicles data
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State to manage error status
  const [totalPages, setTotalPages] = useState(1); // State to manage total pages
  const [totalCount, setTotalCount] = useState(0); // State to manage total vehicle count

  // Debugging effect to log when dependencies change
  useEffect(() => {
    console.log('useFetchVehicles dependencies changed:', { page, limit, filters });
  }, [page, limit, filters]); // This will log whenever these values change

  useEffect(() => {
    let isMounted = true; // flag to track if the component is mounted

    // Function to fetch the total number of vehicles from the API
    const fetchTotalVehicles = async () => {
      try {
        console.log('Fetching total vehicle count with filters:', filters);
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/vehicles/count`, {
          params: filters, // Pass filters to count endpoint
        });
        const count = response.data.total_vehicles;
        if (isMounted) {
          setTotalCount(count);
          setTotalPages(Math.ceil(count / limit)); // Calculate total pages
          console.log('Total vehicles:', count);
        }
      } catch (error) {
        console.error('Error fetching total vehicle count:', error); // Log the error to the console
        if (isMounted) setError('Error fetching total vehicle count'); // Set the error state
      }
    };

    // Function to fetch vehicles data from the API
    const fetchVehicles = async () => {
      setLoading(true);
      try {
        console.log('Fetching vehicles with page:', page, 'limit:', limit, 'filters:', filters);
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/vehicles`, {
          params: { page, limit, ...filters }, // Pass filters to vehicles endpoint
        }); // Make a GET request to fetch vehicles data passing filters and with pagination
        // Sort the vehicles by auction date
        const sortedVehicles = response.data.sort((a, b) => new Date(a.dh_leilao) - new Date(b.dh_leilao));
        console.log('Fetched Vehicles:', sortedVehicles.length); // Debug statement
        if (isMounted) setVehicles(sortedVehicles); // Set the vehicles state with sorted data

        if (isMounted) setLoading(false); // Set loading to false after data is fetched

        // Adding a delay for debugging purposes
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10 seconds delay
      } catch (error) {
        console.error('Error fetching vehicles:', error); // Log the error to the console
        if (isMounted) setError('Error fetching vehicles'); // Set the error state
        if (isMounted) setLoading(false); // Set loading to false in case of error
      }
    };

    fetchTotalVehicles(); // Fetch total vehicle count
    fetchVehicles(); // Call the fetchVehicles function

    return () => {
      isMounted = false; // clean up flag on unmount
    };
  }, [page, limit, filters]); // Re-run the effect when page, limit, or filters change


  return { vehicles, loading, error, page, totalPages, totalCount }; // Return the state values
};

export default useFetchVehicles; // Export the custom hook as the default export
