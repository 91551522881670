// src/hooks/useVehicleFilters.js
import { useState, useEffect } from 'react';
import axios from 'axios';

/**
 * Custom hook to manage vehicle filters.
 *
 * @returns {Object} An object containing filter states, handlers, and filtered vehicles.
 */
const useVehicleFilters = () => {
  // State variables for filters
  const [uniqueBrands, setUniqueBrands] = useState([]);
  const [uniqueModels, setUniqueModels] = useState([]);
  const [uniqueAuctionDates, setUniqueAuctionDates] = useState([]);
  const [uniqueCityStates, setUniqueCityStates] = useState([]);
  const [uniqueOrigens, setUniqueOrigens] = useState([]);
  const [uniqueSinistros, setUniqueSinistros] = useState([]);
  const [uniqueAnoModelos, setUniqueAnoModelos] = useState([]);
  const [uniqueQuilometragens, setUniqueQuilometragens] = useState([]);
  const [uniqueBlindagens, setUniqueBlindagens] = useState([]);
  const [uniqueAuctioneers, setUniqueAuctioneers] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedAuctionDate, setSelectedAuctionDate] = useState('');
  const [selectedCityState, setSelectedCityState] = useState('');
  const [selectedOrigem, setSelectedOrigem] = useState('');
  const [selectedSinistro, setSelectedSinistro] = useState('');
  const [selectedAnoModelo, setSelectedAnoModelo] = useState('');
  const [selectedQuilometragem, setSelectedQuilometragem] = useState('');
  const [selectedBlindagem, setSelectedBlindagem] = useState('');
  const [selectedAuctioneer, setSelectedAuctioneer] = useState('');

  // Fetch unique filter values from the backend
  useEffect(() => {
    let isMounted = true; // flag to track if the component is mounted

    const fetchFilters = async () => {
      try {
        const { data: commonFilters } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/filters/common`);
        const { data: brands } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/filters/brand`);
        const { data: models } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/filters/model`);
        const { data: modelYears } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/filters/model-year`);
        const { data: cities } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/filters/city`);

        if (isMounted) {
          setUniqueBrands(brands);
          setUniqueModels(models);
          setUniqueAuctionDates(commonFilters.auctionDate);
          setUniqueCityStates(cities);
          setUniqueOrigens(commonFilters.origin);
          setUniqueSinistros(commonFilters.sinistro);
          setUniqueAnoModelos(modelYears);
          setUniqueQuilometragens(commonFilters.mileage); 
          setUniqueBlindagens(commonFilters.armor);
          setUniqueAuctioneers(commonFilters.auctioneer);
        }
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    fetchFilters();

    return () => {
      isMounted = false; // clean up flag on unmount
    };
  }, []); // Dependency array ensures this runs once when the component mounts

  // Handlers for updating selected filters
  const handleCategoryChange = (event) => setSelectedCategory(event.target.value);
  const handleBrandChange = (event) => setSelectedBrand(event.target.value);
  const handleModelChange = (event) => setSelectedModel(event.target.value);
  const handleAuctionDateChange = (event) => setSelectedAuctionDate(event.target.value);
  const handleCityStateChange = (event) => setSelectedCityState(event.target.value);
  const handleOrigemChange = (event) => setSelectedOrigem(event.target.value);
  const handleSinistroChange = (event) => setSelectedSinistro(event.target.value);
  const handleAnoModeloChange = (event) => setSelectedAnoModelo(event.target.value);
  const handleQuilometragemChange = (event) => setSelectedQuilometragem(event.target.value);
  const handleBlindagemChange = (event) => setSelectedBlindagem(event.target.value);
  const handleAuctioneerChange = (event) => setSelectedAuctioneer(event.target.value);



  return {
    selectedCategory, handleCategoryChange,
    selectedBrand, handleBrandChange,
    selectedModel, handleModelChange,
    selectedAuctionDate, handleAuctionDateChange,
    selectedCityState, handleCityStateChange,
    selectedOrigem, handleOrigemChange,
    selectedSinistro, handleSinistroChange,
    selectedAnoModelo, handleAnoModeloChange,
    selectedQuilometragem, handleQuilometragemChange,
    selectedBlindagem, handleBlindagemChange,
    selectedAuctioneer, handleAuctioneerChange,
    uniqueBrands, uniqueModels, uniqueAuctionDates, uniqueCityStates, uniqueOrigens,
    uniqueSinistros, uniqueAnoModelos, uniqueQuilometragens, uniqueBlindagens,
    uniqueAuctioneers,
  };
};

export default useVehicleFilters;
