// src/hooks/useFetchVehicleImages.js
import { useState, useEffect } from 'react';
import axios from 'axios';

/**
 * Custom hook to fetch vehicle images data.
 *
 * @param {string} vehicleUUID - The UUID of the vehicle to fetch images for.
 * @param {string} auctioneer - The auctioneer name.
 * @returns {Object} An object containing vehicle images data, loading state, and error state.
 */
const useFetchVehicleImages = (vehicleUUID, auctioneer) => {
  const [images, setImages] = useState([]); // State to hold vehicle images data
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State to manage error status

  useEffect(() => {
    if (vehicleUUID && auctioneer) {
      // Make a GET request to fetch vehicle images by UUID and auctioneer
      axios.get(`api/vehicle/${vehicleUUID}/images?auctioneer=${auctioneer}`) // Make a GET request to fetch vehicle images by UUID
        .then(response => {
          setImages(response.data); // Set the images state with the response data
          setLoading(false); // Set loading to false
        })
        .catch(error => {
          console.error('Error fetching vehicle images:', error); // Log the error to the console
          setError('Error fetching vehicle images'); // Set the error state
          setLoading(false); // Set loading to false
        });
    }
  }, [vehicleUUID]); // Dependency array includes vehicleUUID, so it runs whenever vehicleUUID changes

  return { images, loading, error }; // Return the state values
};

export default useFetchVehicleImages; // Export the custom hook as the default export
