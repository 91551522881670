import { useState, useEffect } from 'react';
import axios from 'axios';

/**
 * Custom hook to fetch similar vehicles data from the backend API.
 *
 * @param {string} categoria - The category of the vehicle.
 * @param {string} marca - The brand of the vehicle.
 * @param {string} modelo - The model of the vehicle.
 * @param {number} ano_modelo - The model year of the vehicle.
 * @param {boolean} triggerFetch - A boolean to trigger the fetch manually.
 * @param {number} limit - The number of vehicles to fetch per request (pagination).
 * @param {number} offset - The offset to start fetching vehicles from (pagination).
 * @returns {Object} An object containing similar vehicles data, loading state, error state, and a refetch function.
 */
const useFetchSimilarVehicles = (categoria, marca, modelo, ano_modelo, triggerFetch, limit = 10, offset = 0) => {
  const [similarVehicles, setSimilarVehicles] = useState([]); // State to hold similar vehicles data
  const [loading, setLoading] = useState(false); // State to manage loading status
  const [error, setError] = useState(null); // State to manage error status

  useEffect(() => {
    if (triggerFetch && categoria && marca && modelo && ano_modelo) {
      setLoading(true); // Set loading to true at the start of the fetch
      setError(null); // Reset error state

      // Make a GET request to fetch similar vehicles with pagination
      axios.get('/api/vehicles/similar-closed', {
        params: { categoria, marca, modelo, ano_modelo, limit, offset }
      })
      .then(response => {
        setSimilarVehicles(response.data); // Set the similar vehicles state with the response data
        setLoading(false); // Set loading to false
      })
      .catch(error => {
        console.error('Error fetching similar vehicles:', error); // Log the error to the console
        setError('Error fetching similar vehicles'); // Set the error state
        setLoading(false); // Set loading to false
      });
    }
  }, [triggerFetch, categoria, marca, modelo, ano_modelo, limit, offset]);  // Dependency array, so it runs whenever these values change

  return { similarVehicles, loading, error }; // Return the state values
};

export default useFetchSimilarVehicles; // Export the custom hook as the default export
