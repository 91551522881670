import React from 'react'; // Import React

// Filters component definition
const Filters = React.memo(({
  selectedCategory, handleCategoryChange,
  uniqueBrands, selectedBrand, handleBrandChange,
  uniqueModels, selectedModel, handleModelChange,
  uniqueAuctionDates, selectedAuctionDate, handleAuctionDateChange,
  uniqueCityStates, selectedCityState, handleCityStateChange,
  selectedOrigem, handleOrigemChange,
  selectedSinistro, handleSinistroChange,
  selectedAnoModelo, handleAnoModeloChange,
  selectedQuilometragem, handleQuilometragemChange,
  selectedBlindagem, handleBlindagemChange,
  uniqueOrigens, uniqueSinistros, uniqueAnoModelos, uniqueQuilometragens, uniqueBlindagens,
  selectedAuctioneer, handleAuctioneerChange, uniqueAuctioneers
}) => {

  return (
    <div className="filter-container"> {/* Container for the filter elements */}
      {/* First row of filters */}
      <div className="filter-row">
        <div>
          <label htmlFor="category">Categoria: </label> {/* Label for category filter */}
          <select id="category" value={selectedCategory} onChange={handleCategoryChange}>
            {/* Options for category filter */}
            <option value="">Tudo</option>
            <option value="Carro">Carro</option>
            <option value="Moto">Moto</option>
            <option value="Caminhão">Caminhão</option>
          </select>
        </div>
        <div>
          <label htmlFor="brand">Marca: </label> {/* Label for brand filter */}
          <select id="brand" value={selectedBrand} onChange={handleBrandChange}>
            {/* Default option for brand filter */}
            <option value="">Tudo</option>
            {/* Dynamically generate options for each unique brand */}
            {uniqueBrands.map(brand => (
              <option key={brand} value={brand}>{brand}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="model">Modelo: </label> {/* Label for model filter */}
          <select id="model" value={selectedModel} onChange={handleModelChange}>
            {/* Default option for model filter */}
            <option value="">Tudo</option>
            {/* Dynamically generate options for each unique model */}
            {uniqueModels.map(model => (
              <option key={model} value={model}>{model}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="auctionDate">Data: </label> {/* Label for auction date filter */}
          <select id="auctionDate" value={selectedAuctionDate} onChange={handleAuctionDateChange}>
            {/* Default option for auction date filter */}
            <option value="">Tudo</option>
            {/* Dynamically generate options for each unique auction date */}
            {uniqueAuctionDates.map(date => (
              <option key={date} value={date}>{date}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="cityState">Cidade: </label> {/* Label for city/state filter */}
          <select id="cityState" value={selectedCityState} onChange={handleCityStateChange}>
            {/* Default option for city/state filter */}
            <option value="">Tudo</option>
            {/* Dynamically generate options for each unique city/state combination */}
            {uniqueCityStates.map(cityState => (
              <option key={cityState} value={cityState}>{cityState}</option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="auctioneer">Leiloeiro: </label> {/* Label for auctioneer filter */}
          <select id="auctioneer" value={selectedAuctioneer} onChange={handleAuctioneerChange}>
            <option value="">Tudo</option>
            {uniqueAuctioneers.map(auctioneer => (
              <option key={auctioneer} value={auctioneer}>{auctioneer}</option>
            ))}
          </select>
        </div>

      </div>

      {/* Second row of filters */}
      <div className="filter-row">
        <div>
          <label htmlFor="origem">Origem: </label>
          <select id="origem" value={selectedOrigem} onChange={handleOrigemChange}>
            <option value="">Tudo</option>
            {uniqueOrigens.map(origem => (
              <option key={origem} value={origem}>{origem}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="sinistro">Sinistro: </label>
          <select id="sinistro" value={selectedSinistro} onChange={handleSinistroChange}>
            <option value="">Tudo</option>
            {uniqueSinistros.map(sinistro => (
              <option key={sinistro} value={sinistro}>{sinistro}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="anoModelo">Ano Modelo: </label>
          <select id="anoModelo" value={selectedAnoModelo} onChange={handleAnoModeloChange}>
            <option value="">Tudo</option>
            {uniqueAnoModelos.map(anoModelo => (
              <option key={anoModelo} value={anoModelo}>{anoModelo}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="quilometragem">Quilometragem: </label>
          <select id="quilometragem" value={selectedQuilometragem} onChange={handleQuilometragemChange}>
            <option value="">Tudo</option> {/* Option for selecting all mileage */}
            {/* Dynamically generate options for each unique mileage */}
            {uniqueQuilometragens.map(quilometragem => (
              <option key={quilometragem} value={quilometragem}>{quilometragem}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="blindagem">Blindagem: </label>
          <select id="blindagem" value={selectedBlindagem} onChange={handleBlindagemChange}>
            <option value="">Tudo</option>
            <option value="Sim">Sim</option>
            <option value="Não">Não</option>
          </select>
        </div>

      </div>
    </div>
  );
});

export default Filters; // Export the Filters component as the default export