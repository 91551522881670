import React, { useState, useEffect } from 'react'; // Import React and necessary hooks
import useFetchVehicleDetail from '../hooks/useFetchVehicleDetail'; // Import the custom hook
import useFetchSimilarVehicles from '../hooks/useFetchSimilarVehicles'; // Import the custom hook for fetching similar vehicles
import VehicleImages from './VehicleImages'; // Import the VehicleImages component
import SimilarVehicles from './SimilarVehicles'; // Import the SimilarVehicles component
import * as utils from './utils'; // Import utility functions

// VehicleDetail component definition
const VehicleDetail = React.memo(({ vehicle }) => {
  // State to control the loading of similar vehicles
  const [loadSimilar, setLoadSimilar] = useState(false);

  // State to manage pagination
  const [offset, setOffset] = useState(0); // Offset for pagination
  const limit = 10; // Number of vehicles to load per request

  // State to hold all fetched similar vehicles
  const [allSimilarVehicles, setAllSimilarVehicles] = useState([]);

  // Fetch the selected vehicle's details using a custom hook
  const { vehicleDetail, loading: detailLoading, error: detailError } = useFetchVehicleDetail(vehicle.uuid, vehicle.leiloeiro);

  // Fetch similar vehicles using a custom hook; updates when loadSimilar, offset, or vehicle details change
  const { similarVehicles, loading: similarLoading, error: similarError } = useFetchSimilarVehicles(
    vehicle.categoria, 
    vehicle.marca, 
    vehicle.modelo, 
    vehicle.ano_modelo, 
    loadSimilar, 
    limit, 
    offset
  );

  // Effect to reset states when a new vehicle is selected
  useEffect(() => {
    setLoadSimilar(false); // Reset loading similar vehicles state
    setOffset(0); // Reset pagination offset
    setAllSimilarVehicles([]); // Clear the list of similar vehicles
}, [vehicle.uuid]);

  // Effect to append newly fetched similar vehicles to the list
  useEffect(() => {
    if (similarVehicles && loadSimilar) {
      setAllSimilarVehicles((prevVehicles) => {
        // Avoid duplication of vehicles by filtering out any duplicates based on uuid
        const newVehicles = similarVehicles.filter(
          (newVehicle) => !prevVehicles.some((prevVehicle) => prevVehicle.uuid === newVehicle.uuid)
        );
        return [...prevVehicles, ...newVehicles];
      });
    }
  }, [similarVehicles, loadSimilar]);

    // Trigger loading of similar vehicles when `loadSimilar` is set to true
    const handleLoadSimilar = () => {
      if (!loadSimilar) {
        setLoadSimilar(true);
      }
    };

  if (!vehicle.uuid) {
    return <p>No vehicle selected</p>;
  }

  if (detailLoading) {
    return <p>Loading...</p>;
  }

  if (detailError) {
    return <p>{detailError}</p>;
  }

  if (!vehicleDetail) {
    return <p>No details available</p>;
  }

  return (
    <div className="vehicle-detail">
      {/* Image Container */}
      <VehicleImages vehicleUUID={vehicle.uuid} auctioneer={vehicle.leiloeiro} /> {/* Use the VehicleImages component */}
      {/* Details Container */}
      <div className="detail-container" style={{ padding: '20px', borderLeft: '1px solid #ddd' }}>
        <h2>{utils.buildVehicleTitle(vehicleDetail)}</h2> {/* Display vehicle brand and model */}
        {/* Render vehicle details */}
        <p><strong>Cidade/Estado:</strong> {`${vehicleDetail.cidade}/${vehicleDetail.estado}`}</p>
        <p><strong>Lance atual:</strong> {utils.formatCurrency(vehicleDetail.lance)}</p>
        <p><strong>Preço FIPE:</strong> {utils.formatCurrency(vehicleDetail.fipe_price)}</p>
        <p><strong>Origem:</strong> {vehicleDetail.origem}</p>
        <p><strong>Sinistro:</strong> {vehicleDetail.sinistro}</p>
        <p><strong>Marca:</strong> {vehicleDetail.marca}</p>
        <p><strong>Modelo:</strong> {vehicleDetail.modelo}</p>
        <p><strong>Versão:</strong> {vehicleDetail.versao}</p>
        <p><strong>Ano Fabricação:</strong> {vehicleDetail.ano_fabricacao}</p>
        <p><strong>Ano Modelo:</strong> {vehicleDetail.ano_modelo}</p>
        <p><strong>Modelo FIPE:</strong> {vehicleDetail.fipe_modelo}</p>
        <p><strong>Final placa:</strong> {vehicleDetail.placa}</p>
        <p><strong>Cor:</strong> {vehicleDetail.cor}</p>
        <p><strong>Quilometragem:</strong> {utils.formatNumber(vehicleDetail.quilometragem)}</p>
        <p><strong>Combustivel:</strong> {vehicleDetail.combustivel}</p>
        <p><strong>Cambio:</strong> {vehicleDetail.cambio}</p>
        <p><strong>Ar condicionado:</strong> {vehicleDetail.ar_cond}</p>
        <p><strong>Direção assistida:</strong> {vehicleDetail.direcao_assist}</p>
        <p><strong>Blindagem:</strong> {utils.formatBoolean(vehicleDetail.blindagem)}</p>
        <p><strong>Vendedor:</strong> {vehicleDetail.vendedor}</p>
        <p><strong>Endereço:</strong> {vehicleDetail.endereco}</p>
        <p><strong>Descrição detalhada:</strong> {vehicleDetail.descricao_det}</p>
        <p><strong>Data/hora leilão:</strong> {utils.formatDate(vehicleDetail.dh_leilao)}</p>
        <p><strong>Data/hora publicação:</strong> {utils.formatDate(vehicleDetail.ts_insert)}</p>
        <p><strong>Última atualização:</strong> {utils.formatDate(vehicleDetail.ts_update)}</p>
        <p><strong>Leiloeiro:</strong> {vehicleDetail.leiloeiro}</p>
        <p>
          <strong>URL: </strong> 
          <a href={vehicleDetail.url} target="_blank" rel="noopener noreferrer">
            {vehicleDetail.url}
          </a>
        </p>
      </div>

      {/* Similar Vehicles Section */}
      <div className="similar-vehicles-container" style={{ marginTop: '20px' }}>
        <h3>Leilões similares encerrados (mesma marca, modelo e ano)</h3>
        
        {!loadSimilar && (
          <button className="load-similar-button" onClick={handleLoadSimilar}>
            Carregar veículos similares
          </button>
        )} {/* Display the button if not clicked */}
        
        {loadSimilar && (
              <>
                <SimilarVehicles vehicles={allSimilarVehicles} loading={similarLoading} /> {/* Render all loaded similar vehicles */}
                {similarLoading && <p>Loading more vehicles...</p>} {/* Display loading indicator below the existing vehicles */}
                {similarError && <p>{similarError}</p>} {/* Display any error that occurs during loading */}
                {!similarLoading && similarVehicles.length === limit && (
                  <button
                    className="load-similar-button"
                    onClick={() => setOffset((prevOffset) => prevOffset + limit)}
                    disabled={similarLoading}
                  >
                    Carregar mais
                  </button>
                )} {/* Display "Load More" button if there are more vehicles to load */}
          </>
        )}
      </div>
    </div>
  );
});

export default VehicleDetail; // Export the VehicleDetail component as the default export
