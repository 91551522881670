// frontend/src/components/VehicleTable.js
import React from 'react'; // Import React
import * as utils from './utils'; // Import everything from utils.js as an object named utils

// VehicleTable component definition
const VehicleTable = React.memo(({ vehicles, onSelectVehicle, selectedVehicleUUID }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Categoria</th>{/* Table header for category */}
          <th>Veículo</th>{/* Table header for brand */}
          <th>Data Leilão</th>{/* Table header for auction date */}
          <th>Lance</th>{/* Table header for bid amount */}
        </tr>
      </thead>
      <tbody>
        {/* Map through the vehicles array to generate table rows */}
        {vehicles.length > 0 ? (
          vehicles.map((vehicle) => (
            <tr 
              key={vehicle.uuid} 
              onClick={() => onSelectVehicle(vehicle)}
              className={vehicle.uuid === selectedVehicleUUID ? 'selected-vehicle' : ''}
              aria-selected={vehicle.uuid === selectedVehicleUUID} // Accessibility feature
            >
              <td>{vehicle.categoria}</td>{/* Display vehicle category */}
              <td>{utils.buildVehicleTitle(vehicle)}</td>{/* Display vehicle model year */}
              <td>{utils.formatDate(vehicle.dh_leilao)}</td>{/* Display formatted auction date */}
              <td>{utils.formatCurrency(vehicle.lance)}</td>{/* Display bid amount */}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" style={{ textAlign: 'center' }}>
              No vehicles available.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
});

export default VehicleTable; // Export the VehicleTable component as the default export
