// src/hooks/useFetchVehicleDetail.js
import { useState, useEffect } from 'react';
import axios from 'axios';

/**
 * Custom hook to fetch vehicle detail data.
 *
 * @param {string} vehicleUUID - The UUID of the vehicle to fetch details for.
 * @param {string} auctioneer - The auctioneer name.
 * @returns {Object} An object containing vehicle detail data, loading state, and error state.
 */
const useFetchVehicleDetail = (vehicleUUID, auctioneer) => {
  const [vehicleDetail, setVehicleDetail] = useState(null); // State to hold vehicle detail data
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State to manage error status

  useEffect(() => {
    if (vehicleUUID && auctioneer) {
      setLoading(true); // Set loading to true at the start of the fetch
      setError(null); // Reset error state
      // Make a GET request to fetch vehicle details by UUID and auctioneer
      axios.get(`/api/vehicle/${vehicleUUID}?auctioneer=${auctioneer}`)
        .then(response => {
          setVehicleDetail(response.data); // Set the vehicle detail state with the response data
          setLoading(false); // Set loading to false
        })
        .catch(error => {
          console.error('Error fetching vehicle details:', error); // Log the error to the console
          setError('Error fetching vehicle details'); // Set the error state
          setLoading(false); // Set loading to false
        });
    }
  }, [vehicleUUID, auctioneer]); // Dependency array includes vehicleUUID, so it runs whenever vehicleUUID changes

  return { vehicleDetail, loading, error }; // Return the state values
};

export default useFetchVehicleDetail; // Export the custom hook as the default export
