// src/components/Header.js
import React from 'react';
import useTheme from '../hooks/useTheme'; // Import the custom hook for theme management

const Header = () => {
  const [theme, toggleTheme] = useTheme(); // Use the custom hook

  return (
    <header className={`header ${theme}-theme`}> {/* Apply theme class */}
      <div className="logo-container">
        {/* Add logo here */}
        <img src="/images/LogoTexto.png" alt="Logo" className="logo" />
        <img src="/images/LogoCarro.png" alt="Logo" className="logo" />
      </div>
      <div>
        {/* Add menu items here */}
        <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </div>
      <div>
        <button onClick={toggleTheme}>
          Switch to {theme === 'light' ? 'Dark' : 'Light'} Theme
        </button>
      </div>
    </header>
  );
};

export default Header;
