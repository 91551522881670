import React from 'react';
import * as utils from './utils'; // Import utility functions

/**
 * SimilarVehicles component to display a list of similar vehicles.
 *
 * @param {Object[]} vehicles - Array of similar vehicles.
* @param {boolean} loading - Loading state to determine if vehicles are being fetched.
* @returns {JSX.Element} The rendered SimilarVehicles component.
 */
const SimilarVehicles = ({ vehicles, loading }) => {
  // If still loading, or no vehicles found after loading completes
  if (!loading && vehicles.length === 0) {
    return <p>No similar vehicles found.</p>;
  }

  return (
    <div className="similar-vehicles">
      {vehicles.map((vehicle) => (
        <div key={vehicle.uuid} className="similar-vehicle-card">
          {/* Vehicle image */}
          <img src={`https://delorean.app/img/${vehicle.leiloeiro === 'Sodré Santoro' ? 'sodre' : 'vip'}/${vehicle.main_photo}`} 
               alt={`${vehicle.marca} ${vehicle.modelo}`} />
          {/* Vehicle information */}
          <div className="similar-vehicle-info">
            <h4>{vehicle.marca} {vehicle.modelo}</h4>
            <p><strong>Sinistro:</strong> {`${vehicle.sinistro}`}</p>
            <p><strong>Encerramento:</strong> {`${vehicle.encerramento}`}</p>
            <p><strong>Data:</strong> {utils.formatDateWYear(vehicle.dh_leilao)}</p>
            <p><strong>Cidade:</strong> {`${vehicle.cidade}/${vehicle.estado}`}</p>
            <p><strong>Lance:</strong> {utils.formatCurrency(vehicle.lance)}</p>
            <p><strong>Leiloeiro:</strong> {vehicle.leiloeiro}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SimilarVehicles; // Export the SimilarVehicles component as the default export
