// src/hooks/useTheme.js
import { useState, useEffect } from 'react';

const useTheme = (initialTheme = 'dark') => {
  const [theme, setTheme] = useState(initialTheme);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return [theme, toggleTheme];
};

export default useTheme;
