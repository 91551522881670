// src/App.js
import React, { useState } from 'react'; // Import React and necessary hooks
import VehicleList from './components/VehicleList'; // Import the VehicleList component
import VehicleDetail from './components/VehicleDetail'; // Import the VehicleDetail component
import Header from './components/Header'; // Import the Header component
import './index.css'; // Import the CSS file

// Main App component
function App() {
  const [selectedVehicle, setSelectedVehicle] = useState(null); // State for selected vehicle

  // Handler for selecting a vehicle from the table
  const handleVehicleSelect = (vehicle) => {
    setSelectedVehicle(vehicle); // Set the selected vehicle
  };

  // Return the JSX to render the component
  return (
    <div className="App">
      <Header /> {/* Add the Header component */}
      <div className="panels"> {/* Ensure panels class is used */}
        <div className="left-panel panel"> {/* Apply CSS classes */}
          <VehicleList onVehicleSelect={handleVehicleSelect} /> {/* Pass the handler to VehicleList */}
        </div>
        <div className="right-panel panel"> {/* Apply CSS classes */}
          {selectedVehicle && <VehicleDetail vehicle={selectedVehicle} />} {/* Pass the vehicle */}
        </div>
      </div>
    </div>
  );
}

export default App; // Export the App component as the default export
