// src/components/VehicleImages.js
import React from 'react';
import ImageGallery from 'react-image-gallery';
import useFetchVehicleImages from '../hooks/useFetchVehicleImages'; // Import the custom hook
import "react-image-gallery/styles/css/image-gallery.css";

const VehicleImages = ({ vehicleUUID, auctioneer }) => {
  const { images, loading, error } = useFetchVehicleImages(vehicleUUID, auctioneer); // Use the custom hook

  if (loading) {
    return <p>Loading images...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }


  //O Sodre ordena as imagens pela ultima letra do nome do arquivo,
  //desconsiderando a extensão do arquivo.
  // Function to extract the character before the file extension.
  const getSortingCharacter = (filename) => {
    const parts = filename.split('_');
    if (parts.length > 1) {
      const namePart = parts[parts.length - 1];
      const lastChar = namePart[namePart.length - 5]; // Getting the character before the file extension
      return lastChar;
    }
    return '';
  };

  // Sort images based on the extracted character
  const sortedImages = images.sort((a, b) => {
    const charA = getSortingCharacter(a.path);
    const charB = getSortingCharacter(b.path);
    return charA.localeCompare(charB);
  });

  // Transform the images data to the format required by react-image-gallery
  const galleryItems = sortedImages.map(imageObj => ({
    // original: `http://${auctioneer === 'Sodre' ? 'dl380:8001' : 'zinho:8000'}/${imageObj.path}`,
    // thumbnail: `http://${auctioneer === 'Sodre' ? 'dl380:8001' : 'zinho:8000'}/${imageObj.path}`,
    original: `https://delorean.app/img/${auctioneer === 'Sodre' ? 'sodre' : 'vip'}/${imageObj.path}`,
    thumbnail: `https://delorean.app/img/${auctioneer === 'Sodre' ? 'sodre' : 'vip'}/${imageObj.path}`,    
  }));

  return (
    <div className="vehicle-images">
      <ImageGallery items={galleryItems} showThumbnails={true} />
    </div>
  );
};

export default VehicleImages; // Export the VehicleImages component as the default export
