import React, { useState, useMemo, useCallback } from 'react'; // Import React and necessary hooks
import Filters from './Filters'; // Import the Filters component
import VehicleTable from './VehicleTable'; // Import the VehicleTable component
import useFetchVehicles from '../hooks/useFetchVehicles'; // Import the useFetchVehicles hook
import useVehicleFilters from '../hooks/useVehicleFilters'; // Import the custom hook

/**
 * VehicleList component to display a list of vehicles with filters and pagination.
 * 
 * @param {function} onVehicleSelect - Function to handle vehicle selection.
 * @returns {JSX.Element} The rendered VehicleList component.
 */
function VehicleList({ onVehicleSelect }) { // Accept the handler as a prop
  const [page, setPage] = useState(1); // State to manage the current page
  const [limit, setLimit] = useState(100); // State to manage the page size

  // Use the custom hook for managing filters
  const {
    selectedCategory, handleCategoryChange,
    selectedBrand, handleBrandChange,
    selectedModel, handleModelChange,
    selectedAuctionDate, handleAuctionDateChange,
    selectedCityState, handleCityStateChange,
    selectedOrigem, handleOrigemChange,
    selectedSinistro, handleSinistroChange,
    selectedAnoModelo, handleAnoModeloChange,
    selectedQuilometragem, handleQuilometragemChange,
    selectedBlindagem, handleBlindagemChange,
    selectedAuctioneer, handleAuctioneerChange,
    uniqueBrands, uniqueModels, uniqueAuctionDates, uniqueCityStates,
    uniqueOrigens, uniqueSinistros, uniqueAnoModelos, uniqueQuilometragens, uniqueBlindagens,
    uniqueAuctioneers,
  } = useVehicleFilters([]);

  // Function to reset page to 1
  const resetPage = useCallback(() => {
    setPage(1);
  }, []);

  // Use useMemo to ensure filters are stable between renders
  const filters = useMemo(
    () => ({
      categoria: selectedCategory,
      marca: selectedBrand,
      modelo: selectedModel,
      dh_leilao: selectedAuctionDate,
      cidade: selectedCityState,
      origem: selectedOrigem,
      sinistro: selectedSinistro,
      ano_modelo: selectedAnoModelo,
      quilometragem: selectedQuilometragem,
      blindagem: selectedBlindagem,
      leiloeiro: selectedAuctioneer,
    }), 
    [
      selectedCategory,
      selectedBrand,
      selectedModel,
      selectedAuctionDate,
      selectedCityState,
      selectedOrigem,
      selectedSinistro,
      selectedAnoModelo,
      selectedQuilometragem,
      selectedBlindagem,
      selectedAuctioneer,
    ]
  );


  // Pass filters to useFetchVehicles
  const { vehicles, loading, error, totalPages, totalCount } = useFetchVehicles(page, limit, filters);

  const [selectedVehicleUUID, setSelectedVehicleUUID] = useState(null); // State to manage selected vehicle

  // Handle vehicle selection
  const handleVehicleSelect = (vehicle) => {
    setSelectedVehicleUUID(vehicle.uuid);
    onVehicleSelect(vehicle); // Pass the entire vehicle object
  };

  // Handle next page
  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Handle previous page
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // Handle limit change
  const handleLimitChange = (event) => {
    setLimit(Number(event.target.value));
    setPage(1); // Reset to first page when limit changes
  };

  // Update filter handlers to reset page
  const handleCategoryChangeWithReset = (event) => {
    handleCategoryChange(event);
    resetPage();
  };

  const handleBrandChangeWithReset = (event) => {
    handleBrandChange(event);
    resetPage();
  };

  const handleModelChangeWithReset = (event) => {
    handleModelChange(event);
    resetPage();
  };

  const handleAuctionDateChangeWithReset = (event) => {
    handleAuctionDateChange(event);
    resetPage();
  };

  const handleCityStateChangeWithReset = (event) => {
    handleCityStateChange(event);
    resetPage();
  };

  const handleOrigemChangeWithReset = (event) => {
    handleOrigemChange(event);
    resetPage();
  };

  const handleSinistroChangeWithReset = (event) => {
    handleSinistroChange(event);
    resetPage();
  };

  const handleAnoModeloChangeWithReset = (event) => {
    handleAnoModeloChange(event);
    resetPage();
  };

  const handleQuilometragemChangeWithReset = (event) => {
    handleQuilometragemChange(event);
    resetPage();
  };

  const handleBlindagemChangeWithReset = (event) => {
    handleBlindagemChange(event);
    resetPage();
  };

  const handleAuctioneerChangeWithReset = (event) => {
    handleAuctioneerChange(event);
    resetPage();
  };

  // Conditional rendering based on loading and error states
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  // JSX to render the VehicleList component
  return (
    <div>
      {/* Render the Filters component */}
      <div className="filter-container">
        <Filters
          selectedCategory={selectedCategory}
          handleCategoryChange={handleCategoryChangeWithReset}
          uniqueBrands={uniqueBrands}
          selectedBrand={selectedBrand}
          handleBrandChange={handleBrandChangeWithReset}
          uniqueModels={uniqueModels}
          selectedModel={selectedModel}
          handleModelChange={handleModelChangeWithReset}
          uniqueAuctionDates={uniqueAuctionDates}
          selectedAuctionDate={selectedAuctionDate}
          handleAuctionDateChange={handleAuctionDateChangeWithReset}
          uniqueCityStates={uniqueCityStates}
          selectedCityState={selectedCityState}
          handleCityStateChange={handleCityStateChangeWithReset}
          selectedOrigem={selectedOrigem}
          handleOrigemChange={handleOrigemChangeWithReset}
          selectedSinistro={selectedSinistro}
          handleSinistroChange={handleSinistroChangeWithReset}
          selectedAnoModelo={selectedAnoModelo}
          handleAnoModeloChange={handleAnoModeloChangeWithReset}
          selectedQuilometragem={selectedQuilometragem}
          handleQuilometragemChange={handleQuilometragemChangeWithReset}
          selectedBlindagem={selectedBlindagem}
          handleBlindagemChange={handleBlindagemChangeWithReset}
          selectedAuctioneer={selectedAuctioneer}
          handleAuctioneerChange={handleAuctioneerChangeWithReset}
          uniqueAuctioneers={uniqueAuctioneers}
          uniqueOrigens={uniqueOrigens}
          uniqueSinistros={uniqueSinistros}
          uniqueAnoModelos={uniqueAnoModelos}
          uniqueQuilometragens={uniqueQuilometragens}
          uniqueBlindagens={uniqueBlindagens}
        />
      </div>

      {/* Display the total vehicle count, pagination controls, and page size selector */}
      <div className="vehicle-list-header">
        <div className="vehicle-count">
          Total veículos ativos: {totalCount}
        </div>
        <div className="pagination-controls">
          <label htmlFor="limit-select">items por página:</label>
          <select id="limit-select" value={limit} onChange={handleLimitChange}>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={300}>300</option>
            <option value={400}>400</option>
            <option value={500}>500</option>
          </select>
          <button onClick={handlePreviousPage} disabled={page === 1}>
            Anterior
          </button>
          <span>Page {page} of {totalPages}</span>
          <button onClick={handleNextPage} disabled={page === totalPages}>
            Próxima
          </button>
        </div>
      </div>

      {/* Render the VehicleTable component */}
      <VehicleTable
        vehicles={vehicles} // Use vehicles from the hook
        selectedVehicleUUID={selectedVehicleUUID}
        onSelectVehicle={handleVehicleSelect}
      />

      {/* Pagination controls at the bottom */}
      <div className="pagination-controls-bottom">
        <button onClick={handlePreviousPage} disabled={page === 1}>
          Previous
        </button>
        <span>Page {page} of {totalPages}</span>
        <button onClick={handleNextPage} disabled={page === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
}

export default VehicleList; // Export the VehicleList component as the default export
