// Exported function to format a date string into a specific format
export const formatDate = (dateString) => {
  // Create a Date object from the input date string
  const date = new Date(dateString);
  
  // Get the day of the month from the Date object
  const day = date.getDate();
  
  // Get the month from the Date object and add 1 because months are zero-indexed
  const month = date.getMonth() + 1;
  
  // Get the hours from the Date object
  const hours = date.getHours();
  
  // Get the minutes from the Date object
  const minutes = date.getMinutes();
  
  // Return the formatted date string in the format "day/month - hours:minutes"
  // Use padStart to ensure minutes are always two digits
  return `${day}/${month} - ${hours}:${minutes.toString().padStart(2, '0')}`;
};


// Exported function to format a date string into a specific format with year
export const formatDateWYear = (dateString) => {
  // Create a Date object from the input date string
  const date = new Date(dateString);

  // Get the day of the month from the Date object
  const day = date.getDate();
  
  // Get the month from the Date object and add 1 because months are zero-indexed
  const month = date.getMonth() + 1;
  
  // Get the last two digits of the year from the Date object
  const year = date.getFullYear().toString().slice(-2).replace(/^0+/, '');
  
  // Return the formatted date string in the format "d/m/yy - h:mm"
  return `${day}/${month}/${year}`;
};



// Exported function to format a number as currency in Brazilian Real
export const formatCurrency = (value) => {
  // Create an Intl.NumberFormat object for Brazilian Real
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  
  // Format the value and return it
  return formatter.format(value);
};

// Exported function to format a number with a thousands separator
export const formatNumber = (value) => {

  if (value === null || value === 0) {
    return 'N/D';
  }

  // Create an Intl.NumberFormat object for locale pt-BR
  const formatter = new Intl.NumberFormat('pt-BR');
  
  // Format the value and return it
  return formatter.format(value);
};

// Exported function to format a boolean value as 'Sim' or 'Não'
export const formatBoolean = (value) => {
  return value ? 'Sim' : 'Não';
};

// Exported function to build the vehicle title
export const buildVehicleTitle = (vehicle) => {
  const { sinistro, blindagem, marca, modelo, ano_modelo } = vehicle;
  const components = [];
  
  if (sinistro && sinistro.toLowerCase().includes('sucata')) {
    components.push('Sucata');
  }
  if (blindagem) {
    components.push('Blindado');
  }
  components.push(marca, modelo, ano_modelo);

  return components.join(' ');
};